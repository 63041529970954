import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Container,
  IconButton,
  Pagination,
  PaginationItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { ReactComponent as AddIcon } from "assets/svg/add-icon.svg";
import { ReactComponent as ArrowIcon } from "assets/svg/pagination-arrow.svg";
import { ReactComponent as CloseIcon } from "assets/svg/remove-icon.svg";
import CollectionsEmptyPlacaholder from "components/CollectionsEmptyPlacaholder";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import ModalChooseSwapCards from "components/Modal/ModalChooseSwapCards";
import { SWAP_CARDS_LIMIT, SWAP_MODAL_TYPES } from "constants/";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { openModal } from "redux/modalSlice";
import { fetchCreateSwapRequest } from "redux/swapsSlice";
import { fetchUserEditions } from "redux/usersSlice";
import { fetchPublicUser } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import { PageLayout } from "../../PageLayout/PageLayout";
import SwapCard from "../SwapCard";
import SwapModal from "../SwapModal";
import styles from "./SwapCreateRequest.module.scss";

const PAGE_LIMIT = 12;

export const SwapCreateRequest = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const [page, setPage] = useState(1);
  const [publicUser, setPublicUser] = useState(null);
  const [choosenCardsIds, setChoosenCardsIds] = useState([]);
  const [requestingCardsIds, setRequestingCardsIds] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const editions = useSelector((state) => state.users.editions.entities);
  const loading = useSelector((state) => state.swaps.loading);
  const userEditionsloading = useSelector(
    (state) => state.users.editions.loading
  );
  const pagesCount = Math.ceil(editions?.length / PAGE_LIMIT);
  const params = location.state;

  useEffect(() => {
    dispatch(fetchUserEditions());
  }, []);

  useEffect(() => {
    if (!params?.userId) return;
    setUserLoading(true);
    dispatch(fetchPublicUser(params?.userId))
      .unwrap()
      .then((userData) => {
        setPublicUser(userData);
        setUserLoading(false);
      })
      .catch((err) => {
        console.log("Cannot get public user", err);
      });
  }, [params?.userId]);

  useEffect(() => {
    if (!params?.cardId || requestingCardsIds.includes(params?.cardId)) return;
    setRequestingCardsIds([parseInt(params?.cardId)]);
  }, [params?.cardId]);

  const getRequestingCards = () => {
    if (!requestingCardsIds.length || !publicUser?.cards?.length) return [];
    return requestingCardsIds?.map((id) =>
      publicUser?.cards?.find((c) => c.id == id)
    );
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePrev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };
  const handleNext = () => {
    if (page === pagesCount) return;
    setPage(page + 1);
  };

  const handleChooseCard = (cardId) => {
    const updatedChoosenCards = updateSelectedItems(cardId, choosenCardsIds);
    setChoosenCardsIds(updatedChoosenCards);
  };

  const handleModalCardClick = (cardId) => {
    const updatedChoosenCards = updateSelectedItems(cardId, requestingCardsIds);
    setRequestingCardsIds(updatedChoosenCards);
    setOpen(false);
  };

  const updateSelectedItems = (itemId, items) => {
    const copied = new Set(items);

    if (copied.size === SWAP_CARDS_LIMIT && !copied.has(itemId)) return;

    if (copied.has(itemId)) {
      copied.delete(itemId);
    } else {
      copied.add(parseInt(itemId));
    }

    return [...copied];
  };

  const getEditionById = (id) => {
    return editions.find((e) => e.id === id);
  };

  const paginatedPosts = useMemo(() => {
    if (editions?.length < PAGE_LIMIT) return editions;
    return [...editions]?.splice(page * PAGE_LIMIT - PAGE_LIMIT, PAGE_LIMIT);
  }, [page, editions]);

  const handleSwapClick = async () => {
    try {
      await dispatch(
        fetchCreateSwapRequest({
          requestedCardIds: requestingCardsIds,
          offeredCardIds: choosenCardsIds,
          toUserId: params.userId,
        })
      ).unwrap();
      openSuccessModal();
    } catch (e) {
      openErrorModal();
    }
  };

  const openSuccessModal = () => {
    dispatch(
      openModal({
        children: (
          <SwapModal
            title={t("swap.success")}
            description={t("swap.requestSentTo", {
              userName: publicUser?.username,
            })}
            btnTitle={t("swap.ok")}
            type={SWAP_MODAL_TYPES.success}
            onBtnClick={() => navigate(-1)}
          />
        ),
      })
    );
  };

  const openErrorModal = () => {
    dispatch(
      openModal({
        children: (
          <SwapModal
            title={t("swap.somethingWentWrong")}
            description={t("swap.trySubmitAgain")}
            btnTitle={t("swap.ok")}
            type={SWAP_MODAL_TYPES.error}
            onBtnClick={() => navigate(-1)}
          />
        ),
      })
    );
  };

  const addMoreHandler = () => {
    setOpen(true);
  };

  const requestingCards = getRequestingCards();
  const modalCards = publicUser?.cards.filter((card) => card.isSwappable);

  const handleRemoveRequestedCard = (card) => {
    const updatedChoosenCards = updateSelectedItems(
      card.id,
      requestingCardsIds
    );

    setRequestingCardsIds(updatedChoosenCards);
  };
  const title = t("swap.swapRequest");

  return (
    <PageLayout title={title} isBack={true}>
      <Container className={styles.container}>
        <Box className={styles.content}>
          <Box className={styles.leftBlock}>
            <Box className={styles.editionsCardsWrap}>
              <Typography className={styles.leftBlockTitle} variant="h6">
                {t("swap.yourCollection")}
              </Typography>
              {userEditionsloading ? (
                <Box className={styles.cardsWrap}>
                  {Array.from(new Array(PAGE_LIMIT)).map((item, i) => (
                    <Skeleton
                      key={i}
                      className={styles.smallCard}
                      variant="rectangular"
                    />
                  ))}
                </Box>
              ) : !!paginatedPosts?.length ? (
                <Box className={styles.cardsWrap}>
                  {paginatedPosts.map((card, i) => {
                    const isSelected = choosenCardsIds.includes(card.id);
                    const isDisabled =
                      (choosenCardsIds.length === SWAP_CARDS_LIMIT &&
                        !isSelected) ||
                      !card.availableForBarter ||
                      !card.isSwappable;
                    return (
                      <SwapCard
                        key={`edition-${card.id}`}
                        className={`${styles.smallCard} ${
                          isDisabled ? styles.disabled : ""
                        }`}
                        {...card}
                        isSmall
                        disabled={isDisabled}
                        isSelected={isSelected}
                        onClick={() => handleChooseCard(card.id)}
                        showAssetHoldings
                      />
                    );
                  })}
                </Box>
              ) : (
                <Box className={styles.emptyWrap}>
                  <CollectionsEmptyPlacaholder
                    title={t("swap.emptyEditions")}
                  />
                </Box>
              )}
              {editions?.length > PAGE_LIMIT && (
                <Box className={styles.paginationWrap}>
                  <Box
                    className={`${styles.btnPrev} ${
                      page === 1 ? styles.disabled : ""
                    }`}
                    onClick={handlePrev}
                  >
                    <ArrowIcon className={styles.arrow} />
                    <Typography variant="textNormal">
                      {t("swap.prev")}
                    </Typography>
                  </Box>

                  <Pagination
                    className={styles.pagination}
                    count={pagesCount}
                    shape="rounded"
                    page={page}
                    onChange={handlePageChange}
                    hideNextButton
                    hidePrevButton
                    siblingCount={0}
                    renderItem={(item) => (
                      <PaginationItem
                        className={styles.paginationItem}
                        {...item}
                      />
                    )}
                  />

                  <Box
                    className={`${styles.btnNext} ${
                      page === pagesCount ? styles.disabled : ""
                    }`}
                    onClick={handleNext}
                  >
                    <Typography variant="textNormal">
                      {t("swap.next")}
                    </Typography>
                    <ArrowIcon className={styles.arrow} />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box className={styles.rightBlock}>
            <Box className={styles.featuredCardWrap}>
              {!publicUser?.username ? (
                <Skeleton
                  className={styles.featuredCardOwnerSkeleton}
                  variant="rectangular"
                />
              ) : (
                <Typography className={styles.featuredCardOwner} variant="h6">
                  {publicUser?.username} {t("swap.offered")}
                </Typography>
              )}
              <Box className={styles.requestedSlotsWrap}>
                {userLoading && !requestingCards?.length && (
                  <Skeleton className={styles.card} variant="rectangular" />
                )}
                {requestingCards?.length > 0 &&
                  requestingCards.map((card) => (
                    <Box
                      key={`req-${card?.id + Math.random()}`}
                      className={styles.card}
                    >
                      <IconButton
                        className={styles.removeCardBtn}
                        onClick={() => handleRemoveRequestedCard(card)}
                      >
                        <CloseIcon />
                      </IconButton>
                      <SwapCard
                        className={styles.featuredCard}
                        influencerName={card?.influencer?.name}
                        {...card}
                        onClick={addMoreHandler}
                      />
                    </Box>
                  ))}

                {requestingCards?.length < SWAP_CARDS_LIMIT && (
                  <IconButton
                    onClick={addMoreHandler}
                    disabled={userLoading}
                    className={`${styles.card} ${styles.addCardBtn}`}
                  >
                    <Box className={styles.addBtnContent}>
                      <Typography
                        variant="textNormal"
                        className={styles.addBtnTitle}
                      >
                        {t("swap.addCardForBarter")}
                      </Typography>
                      <AddIcon className={styles.addBtnIcon} />
                    </Box>
                  </IconButton>
                )}
              </Box>
            </Box>
            <Box className={styles.slotsBlock}>
              <Typography className={styles.slotsTitle} variant="h6">
                {t("swap.youOffered")}
              </Typography>
              <Box className={styles.slotsWrap}>
                <Box className={styles.card}>
                  {choosenCardsIds[0] && (
                    <SwapCard
                      disabled
                      className={styles.featuredCard}
                      {...getEditionById(choosenCardsIds[0])}
                    />
                  )}
                </Box>
                <Box className={styles.card}>
                  {choosenCardsIds[1] && (
                    <SwapCard
                      disabled
                      className={styles.featuredCard}
                      {...getEditionById(choosenCardsIds[1])}
                    />
                  )}
                </Box>
                <Box className={styles.card}>
                  {choosenCardsIds[2] && (
                    <SwapCard
                      disabled
                      className={styles.featuredCard}
                      {...getEditionById(choosenCardsIds[2])}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box className={styles.btnsWrap}>
              <Button
                variant="outlined"
                className={`${styles.btn} ${styles.cancelBtn}`}
                onClick={() => navigate(-1)}
              >
                {t("swap.cancel")}
              </Button>
              <LoadingButton
                variant="contained"
                className={`${styles.btn} ${styles.swapBtn}`}
                onClick={handleSwapClick}
                loading={loading}
                disabled={
                  !choosenCardsIds.length ||
                  !requestingCardsIds?.length ||
                  !editions?.length ||
                  !params?.userId
                }
              >
                {t("swap.exchange")}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
        <ModalChooseSwapCards
          open={open}
          cards={modalCards}
          selectedCardsIds={requestingCardsIds}
          handleClose={() => setOpen(false)}
          handleCardClick={handleModalCardClick}
          userName={publicUser?.username}
        />
      </Container>
    </PageLayout>
  );
};

export default SwapCreateRequest;
