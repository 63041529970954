export const DepositCardsIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.30801 6.51538H1.39814C0.940112 6.51538 0.577148 6.88699 0.577148 7.33637V16.6783C0.577148 17.1364 0.948754 17.4993 1.39814 17.4993H7.99196C8.44999 17.4993 8.81295 17.1277 8.81295 16.6783V15.1125"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M7.05767 11.5646L5.46539 11.139C5.02465 11.018 4.76539 10.5686 4.87773 10.1279L7.29749 1.11431C7.41848 0.673574 7.86786 0.414314 8.3086 0.52666L14.6777 2.22913C15.1185 2.35012 15.3777 2.7995 15.2654 3.24024L13.8445 8.55721"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M10.5599 4.66357L9.8068 6.24382L8.05371 6.15123L9.63396 6.91049L9.54137 8.66357L10.3006 7.08333L12.0537 7.16975L10.4735 6.41666L10.5599 4.66357Z"
        fill="white"
      />
      <path
        d="M8.42871 13.0182H15.584M15.584 13.0182L13.5964 14.8135M15.584 13.0182L13.5964 11.2358"
        stroke="white"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PostsIcon = () => (
  <svg
    width="72"
    height="66"
    viewBox="0 0 72 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.2188 33.5625C50.2188 35.7716 48.4279 37.5625 46.2188 37.5625H10.6649C9.54241 37.5625 8.47157 38.0341 7.71383 38.8623L4.94428 41.8891C3.71237 43.2355 1.46875 42.3639 1.46875 40.539V37.5625V5C1.46875 2.79086 3.25961 1 5.46875 1H46.2188C48.4279 1 50.2188 2.79086 50.2188 5V33.5625Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M21.7812 47.3125V53.875C21.7812 56.0841 23.5721 57.875 25.7813 57.875H61.3351C62.4576 57.875 63.5284 58.3466 64.2862 59.1748L67.0557 62.2016C68.2876 63.548 70.5312 62.6764 70.5312 60.8515V57.875V25.3125C70.5312 23.1034 68.7404 21.3125 66.5312 21.3125H60.05"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M42.0938 13.1875L9.59375 13.1875"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.0938 25.375L9.59375 25.375"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CollectionIcon = () => (
  <svg
    width="62"
    height="67"
    viewBox="0 0 62 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7528 21.7019H5.05029C3.20308 21.7019 1.73926 23.2006 1.73926 25.0129V62.6891C1.73926 64.5363 3.23794 66.0001 5.05029 66.0001H31.6431C33.4904 66.0001 34.9542 64.5014 34.9542 62.6891V57.1474"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M46.3185 50.7711L20.6318 43.9051C18.8543 43.4171 17.8087 41.6048 18.2618 39.8273L28.0206 3.47556C28.5086 1.69805 30.3209 0.652461 32.0985 1.10555L57.7851 7.9716C59.5626 8.45954 60.6082 10.2719 60.1551 12.0494L50.4311 48.4359C49.9083 50.2135 48.096 51.259 46.3185 50.7711Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M38.2679 26.5143L34.7129 24.8065L38.6513 25.0156L40.3591 21.4258L40.15 25.399L43.705 27.1068L39.7666 26.8977L38.0588 30.4527L38.2679 26.5143Z"
      fill="white"
    />
    <path
      d="M42.0673 14.6611L37.8153 23.5835L27.917 23.0607L36.8394 27.3476L36.3166 37.2459L40.6035 28.3235L50.5017 28.8115L41.5794 24.5594L42.0673 14.6611Z"
      fill="white"
    />
  </svg>
);

export const EmptyStateFriendsIcon = () => (
  <svg
    width="60"
    height="36"
    viewBox="0 0 60 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1998 15.877C13.369 16.7078 12.2152 17.2309 10.9382 17.2309C8.38436 17.2309 6.3228 15.1693 6.3228 12.6154C6.3228 10.0616 8.38436 8 10.9382 8C13.4921 8 15.5537 10.0616 15.5537 12.6154M3.24586 28C-0.600338 17.2307 6.69205 21.8461 10.9382 21.8461C12.5638 21.8461 14.6358 21.1697 16.3584 20.939"
      stroke="white"
      strokeWidth="1.2658"
      strokeLinejoin="round"
    />
    <path
      d="M35.6203 14.6032C34.291 15.9324 32.4449 16.7694 30.4018 16.7694C26.3156 16.7694 23.0171 13.4709 23.0171 9.38469C23.0171 5.2985 26.3156 2 30.4018 2C34.488 2 37.7864 5.2985 37.7864 9.38469M18.094 34C11.94 16.7691 23.6079 24.1537 30.4018 24.1537C37.1957 24.1537 48.8635 16.7691 42.7096 34"
      stroke="white"
      strokeWidth="1.2658"
      strokeLinejoin="round"
    />
    <path
      d="M52.3223 15.877C51.4915 16.7078 50.3377 17.2309 49.0607 17.2309C46.5069 17.2309 44.4453 15.1693 44.4453 12.6154C44.4453 10.0616 46.5069 8 49.0607 8C51.6146 8 53.6762 10.0616 53.6762 12.6154M56.7531 28C60.5993 17.2307 53.307 21.8461 49.0608 21.8461C47.7815 21.8461 46.2256 21.4271 44.7813 21.1362"
      stroke="white"
      strokeWidth="1.2658"
      strokeLinejoin="round"
    />
  </svg>
);

export const EmptyStateChallengesIcon = () => (
  <svg
    width="36"
    height="34"
    viewBox="0 0 36 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.09871 10.59L3.27361 8.65997C1.84539 8.08096 0.359259 9.33549 0.668065 10.8409L2.6367 20.356C3.00341 22.1509 4.58604 23.4248 6.41957 23.4248H11.1482"
      stroke="white"
      strokeWidth="1.27"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M27.9017 10.6089L32.7268 8.67889C34.155 8.09988 35.6411 9.35441 35.3323 10.8598L33.3637 20.3749C32.997 22.1698 31.4143 23.4437 29.5808 23.4437H25.3154"
      stroke="white"
      strokeWidth="1.27"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.3799 33.0008H24.2725"
      stroke="white"
      strokeWidth="1.27"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10.5304 23.2525C9.14074 21.5734 8.29152 19.4311 8.29152 17.0764V10.1283C8.29152 8.83515 7.63531 7.59993 6.55449 6.90512C6.36148 6.78931 6.18778 6.65421 6.01408 6.48051C5.43507 5.9015 5.06836 5.09088 5.06836 4.20307C5.06836 3.31525 5.43507 2.50463 6.01408 1.92562C6.61239 1.36591 7.4037 0.999207 8.29152 0.999207H27.5726C28.4604 0.999207 29.271 1.36591 29.85 1.94492C30.429 2.52394 30.7957 3.33455 30.7957 4.22237C30.7957 5.11018 30.429 5.9208 29.85 6.49981C29.6956 6.65421 29.5026 6.80862 29.3289 6.92442C28.2288 7.63853 27.5919 8.85445 27.5919 10.1476V16.8255C27.5919 22.1331 23.2493 26.688 17.9417 26.7266V32.9992"
      stroke="white"
      strokeWidth="1.27"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReferralsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8.00752C7.8177 6.86839 6.8084 6 5.58606 6C4.2341 6 3.14276 7.06233 3.14276 8.37834C3.14276 9.69436 4.2341 10.7567 5.58606 10.7567C6.12958 10.7567 6.63098 10.5824 7.03522 10.2916M1 16C1.04001 13.1946 3.80065 13.1349 5.58607 13.1349C6.11142 13.1349 6.70999 13.1402 7.29288 13.2191"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.1932 10.8948C17.4206 11.6558 16.3474 12.1349 15.1598 12.1349C12.7846 12.1349 10.8672 10.2466 10.8672 7.90729C10.8672 5.56802 12.7846 3.67969 15.1598 3.67969C17.535 3.67969 19.4524 5.56802 19.4524 7.90729"
      stroke="white"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M7 20V20C7 17.5 9 15.5 11.5 15.5H14.1"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.5 22V15"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 18.5H23"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
