import { format } from "date-fns";
import DOMPurify from "dompurify";

import { PATHS, REWARDS_ACTION_TYPE, SCROLL_THRESHOLD } from "../constants";

const HEDERA_LINK_TESTNET =
  "https://testnet.hederaexplorer.io/search-details/token";
const HEDERA_LINK = "https://hederaexplorer.io/search-details/token";

const HEDERA_LINK_PROFILE_TESTNET =
  "https://testnet.hederaexplorer.io/search-details/account";
const HEDERA_LINK_PROFILE = "https://hederaexplorer.io/search-details/account";

export const hederaLinkProfile = () => {
  if (process.env.REACT_APP_ENV === "production") {
    return HEDERA_LINK_PROFILE;
  }

  return HEDERA_LINK_PROFILE_TESTNET;
};

export const hederaLink = () => {
  if (process.env.REACT_APP_ENV === "production") {
    return HEDERA_LINK;
  }

  return HEDERA_LINK_TESTNET;
};

export const urlify = (text) => {
  const urlRegex = /https?:\/\/[^\s<>]+/g;
  return text.replace(urlRegex, function (url) {
    // Check if the URL is already inside an anchor tag
    if (/<a\b[^>]*>(.*?)<\/a>/i.test(url)) {
      return url;
    }
    return `<a href="${url}" target="_blank" onclick="event.stopPropagation()" rel="noopener noreferrer">${url}</a>`;
  });
};

export const formatPostMessage = (msg) =>
  msg
    .replace(/\t/g, "")
    .replace(/\n\n/g, "\n")
    .split("\n")
    .map((line, index) => (
      <p
        key={index}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(line, {
            ALLOWED_ATTR: ["target", "onclick", "href", "rel"],
          }),
        }}
      />
    ));

export const getInfluencerProfileLink = ({ id, handle }) => {
  if (!id && !handle) return null;
  return handle
    ? `/${handle}`
    : PATHS.DISCOVER_INFLUENCER_ID.replace(":id", id);
};

const extractTokenNumber = (tokenSerial) => {
  const tokenNumber = tokenSerial.split("-")[1];
  return tokenNumber;
};

export const formatEditionOrders = (data, amountCards = 0) => {
  let result = {};
  const rows = data?.rows || data?.tokens;

  if (rows?.length) {
    result.amountCards = amountCards;
    result.tokens = rows.map((item) => {
      const {
        id,
        price,
        purchasePrice,
        tokenSerial,
        username,
        userPictureURL,
        currencySymbol,
      } = item;
      return {
        ...item,
        id: extractTokenNumber(tokenSerial),
        tokenSerial,
        orderId: id,
        purchasePrice: price || purchasePrice,
        username,
        userPictureURL,
        currencySymbol,
        rewardStatus: item.rewardStatus,
      };
    });
  }

  return result;
};

export const goToInfluencer =
  ({ id, handle, navigate, location }) =>
  () => {
    const from = location.search
      ? `${location.pathname}${location.search}`
      : location.pathname;
    navigate(
      getInfluencerProfileLink({
        id,
        handle,
      }),
      {
        state: { from, backRouteInfluencer: from },
      }
    );
  };

//converter array of objects to object with objects
/**
 * @param {string} key
 * @param {[object]} arr
 * */
export const arrayToObjConverter = ({ key, arr }) => {
  const obj = {};
  arr.forEach((item) => {
    obj[item[key]] = item;
  });
  return obj;
};

export const getConvertedTimeZoneDate = (date, dateFormat) => {
  const lang = localStorage.getItem("ii-language");

  return lang && !dateFormat
    ? new Date(date).toLocaleDateString(lang, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : format(new Date(date), dateFormat || "dd MMM yyyy");
};

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Checks for iOS and Android devices
  return /android|iphone|ipad|ipod|windows phone/i.test(userAgent);
};

export const getScrollThreshold = () => {
  return window.innerHeight > 1900
    ? "1500px"
    : window.innerHeight > 1200
    ? "900px"
    : window.innerHeight > 1000
    ? "500px"
    : SCROLL_THRESHOLD;
};

export const getRewardsActionType = ({ type, t }) => {
  const data = {
    [REWARDS_ACTION_TYPE.HIDDEN_CONTENT]: t("default.hiddenContent"),
    [REWARDS_ACTION_TYPE.PREMIUM_CHAT]: t("default.premiumChat"),
    [REWARDS_ACTION_TYPE.FOLLOW_BACK]: t("default.followBack"),
    [REWARDS_ACTION_TYPE.VIDEO_CALL]: t("default.videoCall"),
  };
  return data[type];
};

export const storageCleaner = () => {
  localStorage.removeItem("from");
};
