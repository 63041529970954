export const getUsersMeId = (state) => state.users.me.entities?.id;
export const getUsersMe = (state) => state.users.me.entities;
export const getProfile = (state) => state.users.entities;
export const getProfileId = (state) => state.users.entities?.id;
export const getProfileIsCompleted = (state) =>
  state.users.entities?.isCompleted;
export const getUsersMeLoading = (state) => state.users.me.loading;
export const getUsersMeLevel = (state) => state.users.me.entities?.level;
export const getUsersMeWalletAddress = (state) =>
  state.users.me.entities?.walletAddress;
export const getUsersActiveDaysStreak = (state) =>
  state.users.entities?.activeDaysStreak;
export const getUserInviteCode = (state) => state.users.entities?.inviteCode;
export const getUserSeenTutorials = (state) =>
  state.users.entities.seenTutorials;
export const getBadgesSelector = (state) => state.users.badges?.entities;
export const getUsersLoading = (state) => state.users.loading;
export const getBadgesTotalSelector = (state) => state.users.badges?.total;
export const getMyFollowersSelector = (state) =>
  state.users.followers.my.entities;
export const getMyFollowersLengthSelector = (state) =>
  state.users.followers.my.entities.length;
export const getMyFollowersLoaderSelector = (state) =>
  state.users.followers.my.loading;
export const getMyConnectionsLoaderSelector = (state) =>
  state.users.connections.loading;
export const getMyConnections = (state) => state.users.connections?.entities;
export const getMyConnectionsIsLastPage = (state) =>
  state.users.connections?.entities?.isLastPage;
export const getConnectionsRequests = (state) =>
  state.users.connections?.requests;
export const getMyConnectionsFriendsWithPostsSelector = (state) =>
  state.users.connections.whoPosted.rows;
export const getMyConnectionsFriendsWithPostsLengthSelector = (state) =>
  state.users.connections.whoPosted.rows.length;
export const getPublicUser = (state) => state.users.publicUser.entities;
export const getPublicUserLoading = (state) => state.users.publicUser.loading;
export const getUserEdition = (state) => state.users.editions.entities;
export const getUserEditionLoader = (state) => state.users.editions.loading;
export const getUserWallet = (state) => state.users.wallet.entities;
export const getUserWalletLoading = (state) => state.users.wallet.loading;
