import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as MessageIcon } from "assets/svg/message-icon.svg";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { setActiveChat } from "redux/messengerSlice";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import { Messenger } from "./components/Messenger/Messenger";
import styles from "./Messages.module.scss";

const Messages = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:1140px)");

  const newMessageHandler = () => {
    dispatch(setActiveChat({ isNewMessageTo: true }));
  };

  return (
    <PageLayout
      title={isMobile ? t("messages.messages") : ""}
      customMobileHeaderMenu={<></>}
    >
      <Box className={styles.container}>
        <Box className={styles.titleWrapper}>
          {!isMobile ? (
            <Typography variant="h2" className={styles.title}>
              {t("messages.messages")}
            </Typography>
          ) : null}
          <Button variant="outlined" onClick={newMessageHandler}>
            <MessageIcon />
            <Box className={styles.btnText}>{t("messages.newMessage")}</Box>
          </Button>
        </Box>
        <Box className={styles.mainContent}>
          <Messenger />
        </Box>
      </Box>
    </PageLayout>
  );
};
export default Messages;
