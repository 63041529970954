import {
  Box,
  CircularProgress,
  Container,
  Skeleton,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TutorialTooltip } from "components/TutorialTooltip/TutorialTooltip";
import { SCROLL_THRESHOLD, TUTORIALS_PROPS } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { getUserSeenTutorials } from "redux/selectors/usersSelectors";
import { updateUserEntities, updateUserProfile } from "redux/usersSlice";
import { getInfluencers } from "services/discoverApi";
import { LocalizationContext } from "services/localizationContext";
import {
  initialDataState,
  initialLoaders,
  LEVEL_GROUP,
} from "utils/getFilteredInfluencers";

import { Card } from "../../../components/InfluencersRows/Groups/components/Card/Card";
import { PageLayout } from "../../PageLayout/PageLayout";
import { CategoriesList } from "../components/CategoriesList/CategoriesList";
// import { Filter } from "../components/Filter/Filter";
import { MainHeader } from "../components/MainHeader/MainHeader";
import styles from "../discover.module.scss";
import { EmptyStateIcon } from "../icons";
// import { handleSort, initialFilterState } from "../utils";

const DiscoverGroups = () => {
  const isMd = useMediaQuery("(max-width:1024px)");
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const seenTutorials = useSelector(getUserSeenTutorials);

  const [data, setData] = useState(initialDataState);
  const [loaders, setLoaders] = useState(initialLoaders);
  const [offset, setOffset] = useState(0);
  const [category, setCategory] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  // const [showFilters, setShowFilters] = useState(false);
  // const [filter, setFilter] = useState({
  //   ...initialFilterState,
  //   orderBy: "createdAt",
  // });

  const isEmpty = !data.groups.length;

  const setCategoryHandler = (value) => {
    setCategory(value);
    setOffset(0);
  };

  const loadMore = () => setOffset((prevState) => prevState + 1);

  const closeTutorialTooltip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.GROUPS_TUTORIAL_1]: true,
      },
    };
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  // const sort = () => {
  //   handleSort({
  //     filter,
  //     setLoaders,
  //     setData,
  //     setFilter,
  //     params: { limit: 25 },
  //     rest: { preventBigger: true, preventSmaller: true },
  //   });
  // };

  // useEffect(() => {
  //   getFilteredInfluencers({
  //     params: { ...filter, limit: 25 },
  //     setLoaders,
  //     setData,
  //     preventBigger: true,
  //     preventSmaller: true,
  //   });
  // }, []);

  useEffect(() => {
    setLoaders((prevState) => ({ ...prevState, groupsLoader: true }));
    getInfluencers({
      level: LEVEL_GROUP,
      limit: 25,
      category,
      ...(offset && { offset: 25 * offset }),
    })
      .then((res) => {
        if (res?.data) {
          setData((prevState) => ({
            ...prevState,
            groups: offset ? data.groups.concat(res.data) : res.data,
          }));
        }
        if (!res.data?.length) {
          setHasMore(false);
        } else {
          setHasMore(res.data.length % 25 === 0);
        }
      })
      .finally(() =>
        setLoaders((prevState) => ({ ...prevState, groupsLoader: false }))
      );
  }, [offset, category]);

  return (
    <PageLayout
      title={
        <TutorialTooltip
          isOpen={!seenTutorials?.[TUTORIALS_PROPS.GROUPS_TUTORIAL_1]}
          setOpen={closeTutorialTooltip}
          placement={isMd ? "bottom" : "right"}
          title={t("default.groupsTutorialTitle")}
          subTitle={t("default.groupsSubTutorialTitle")}
        >
          {t("discover.groups")}
        </TutorialTooltip>
      }
      isSearch={true}
    >
      {/*{showFilters ? (*/}
      {/*  <Filter*/}
      {/*    setFilter={setFilter}*/}
      {/*    data={filter}*/}
      {/*    getFilteredInfluencers={getFilteredInfluencers}*/}
      {/*    setShowFilters={setShowFilters}*/}
      {/*    setLoaders={setLoaders}*/}
      {/*    setData={setData}*/}
      {/*    preventBigger={true}*/}
      {/*    preventSmaller={true}*/}
      {/*  />*/}
      {/*) : null}*/}
      <CategoriesList
        setCategory={setCategoryHandler}
        category={category}
        loader={loaders.groupsLoader}
      />
      {loaders.groupsLoader && isEmpty ? (
        <Box className={styles.gridGroup}>
          <Skeleton
            variant="rectangular"
            className={styles.skeletonCardGroup}
          />
          <Skeleton
            variant="rectangular"
            className={styles.skeletonCardGroup}
          />
        </Box>
      ) : null}
      {isEmpty && !loaders.groupsLoader ? (
        <Box className={styles.emptyState}>
          <EmptyStateIcon />
          <Typography>{t("discover.discoverEmptyState")}</Typography>
        </Box>
      ) : (
        <InfiniteScroll
          dataLength={data.groups?.length}
          next={loadMore}
          hasMore={hasMore}
          scrollThreshold={SCROLL_THRESHOLD}
          scrollableTarget="scrollableEl"
          className={styles.gridGroup}
          loader={
            <Box className={styles.gridGroupLoader}>
              <CircularProgress />
            </Box>
          }
        >
          {data.groups.map((card) => (
            <Card card={card} key={card.id} />
          ))}
        </InfiniteScroll>
      )}
    </PageLayout>
  );
};

export default DiscoverGroups;
