import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isAuthenticated } from "services/auth.service";

import { PATHS } from "../constants";

export const RequireAuth = () => {
  let location = useLocation();
  if (!isAuthenticated()) {
    const to = location.state?.redirectTo || "/sign-up";
    localStorage.setItem("from", location.pathname);
    return <Navigate to={to} state={{ backRoute: location }} replace />;
  }

  return <Outlet />;
};

export const PublicRoute = () => {
  let location = useLocation();
  if (isAuthenticated()) {
    if (
      !location.pathname.includes(PATHS.LOGIN) &&
      !location.pathname.includes(PATHS.SIGN_UP) &&
      !location.pathname.includes(PATHS.FORGOT_PASSWORD_CREATOR) &&
      !location.pathname.includes(PATHS.FORGOT_PASSWORD_FAN)
    ) {
      return <Navigate to="/home" state={{ backRoute: location }} replace />;
    }
  }
  return <Outlet />;
};
