import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ActiveLink from "components/ActiveLink";
import defaultStyles from "components/Layout/Header/Header.module.scss";
import { ANALYTICS_EVENTS, PATHS } from "constants/index";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { getGlobalCountUnreadMessages } from "redux/selectors/messengerSelectors";
import Analytics from "services/Analytics.service";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

export const NavMenu = ({ overrideStyles, handleClose, children, ...rest }) => {
  const messages = useSelector(getGlobalCountUnreadMessages);

  const sendAnalytics = (pageName) => {
    Analytics.track(ANALYTICS_EVENTS.APP_CLICK_TOP_MENU, {
      pageName,
    });
  };
  const { t } = useContext(LocalizationContext);
  const styles = overrideStyles || defaultStyles;
  const isMobile = useMediaQuery("(max-width:1140px)");
  const MENU_ITEMS = [
    {
      to: PATHS.HOME,
      text: t("home.home"),
      isHidden: !isMobile,
    },
    {
      to: PATHS.DISCOVER,
      text: t("home.creators"),
    },
    {
      to: PATHS.DISCOVER_GROUPS,
      text: t("home.groups"),
    },
    {
      to: PATHS.DROPS,
      text: t("home.marketplace"),
    },
    {
      to: PATHS.CHAT,
      text: t("home.messages"),
      isHidden: !isMobile,
      value: messages,
    },
    {
      to: PATHS.SEARCH,
      text: t("home.search"),
      isHidden: !isMobile,
    },
  ];
  return (
    <Box className={styles.navWrap} {...rest}>
      <div
        className={styles.menu}
        onClick={(e) => sendAnalytics(e.target.innerHTML)}
      >
        {MENU_ITEMS.map(
          (item, idx) =>
            !item.isHidden && (
              <ActiveLink
                to={item.to}
                key={idx}
                className={styles.menuItem}
                activeClass={styles.menuItemActive}
                onClick={handleClose}
              >
                <Typography variant="h6" className={styles.menuItemText}>
                  {item.text}{" "}
                  {item.value ? (
                    <Typography
                      variant="clash_display_12_500"
                      className={styles.menuItemTextValue}
                      component="span"
                    >
                      {item.value}
                    </Typography>
                  ) : null}
                </Typography>
              </ActiveLink>
            )
        )}
      </div>
      {children}
    </Box>
  );
};
