import { Box, Grid, Skeleton, Typography } from "@mui/material";
import Title from "components/Title";
import TransfersCard from "components/TransfersCard";
import { PATHS, SCROLL_THRESHOLD, TRANSFERS_LOAD_LIMIT } from "constants/";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "redux/appSlice";
import { fetchTransfers } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Settings.module.scss";

export const Transfers = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [itemsOffset, setItemsOffset] = useState(0);
  const hasMore = useSelector((state) => state.users.transfers.hasMore);
  const loading = useSelector((state) => state.users.transfers.loading);
  const transfers = useSelector((state) => state.users.transfers.entities);
  const user = useSelector((state) => state.users.entities);

  const getTransfers = async (offsetParam) => {
    dispatch(setLoader(true));
    setItemsOffset(offsetParam);

    const payload = {
      offset: 0,
      limit: offsetParam + TRANSFERS_LOAD_LIMIT,
    };

    await dispatch(fetchTransfers(payload)).finally(() =>
      dispatch(setLoader(false))
    );
  };

  useEffect(() => {
    let unmounted = false;
    !unmounted && getTransfers(0);

    return () => {
      unmounted = true;
    };
  }, []);

  const Loader = () => (
    <>
      <Grid item xs={4}>
        <Skeleton
          variant="rectangular"
          sx={{
            bgcolor: "#21264e",
            height: 87,
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Skeleton
          variant="rectangular"
          sx={{ bgcolor: "#21264e", height: 87, borderRadius: "10px" }}
        />
      </Grid>
    </>
  );

  return (
    <Box>
      <Title title={t("settings.transfers")} backLink={PATHS.SETTINGS} />
      {transfers?.length > 0 ? (
        <InfiniteScroll
          dataLength={transfers?.length || 0}
          next={() => getTransfers(itemsOffset + TRANSFERS_LOAD_LIMIT)}
          hasMore={hasMore}
          loader={<Loader />}
          endMessage=""
          scrollThreshold={SCROLL_THRESHOLD}
          className={styles.transfersContainer}
        >
          {transfers.map((t) => (
            <TransfersCard key={t.id} {...t} locale={user.language} />
          ))}
        </InfiniteScroll>
      ) : (
        <>
          {!hasMore && !loading && (
            <Typography variant="h6">{t("settings.noTransfers")}</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default Transfers;
