import { Box } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./ChallengesHub.module.scss";
import { Badges } from "./components/Badges/Badges";
import { Challenges } from "./components/Challenges/Challenges";
import { LoginStreak } from "./components/LoginStreak/LoginStreak";

export const ChallengesHub = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <PageLayout
      title={t("challenges.challengesHub")}
      customMobileHeaderMenu={<></>}
    >
      <Box className={styles.content}>
        <Box className={styles.top}>
          <Box className={styles.topLeft}>
            <Badges />
          </Box>
          <Box className={styles.topRight}>
            <LoginStreak wrapperClassName={styles.loginStreak} />
          </Box>
        </Box>
        <Challenges />
      </Box>
    </PageLayout>
  );
};
