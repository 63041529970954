import {
  Box,
  Button,
  CircularProgress,
  Container,
  Skeleton,
  Typography,
} from "@mui/material";
import { ReactComponent as BulletIcon } from "assets/svg/bullet-icon.svg";
import { ReactComponent as PlaceholderIcon } from "assets/svg/no-orders-icon.svg";
import Toaster from "components/Toaster";
import TransactionItem from "components/TransactionItem";
import {
  ORDER_TYPES,
  SCROLL_THRESHOLD,
  TRANSACTIONS_LOAD_LIMIT,
  TRANSACTIONS_STATUSES,
} from "constants/index";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { cancelTransaction, fetchUserTransactions } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { formatDate, isDateInArray } from "utils/dateHelper";
import { roundToTwo } from "utils/math";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./transactions.module.scss";

const Placeholder = ({ text }) => {
  return (
    <Box className={styles.placeholder}>
      <PlaceholderIcon
        width={100}
        height={100}
        className={styles.placeholderIcon}
      />
      <Typography
        variant="h4"
        component="h4"
        className={styles.placeholderText}
      >
        {text}
      </Typography>
    </Box>
  );
};

const Skeletons = () =>
  Array.from(new Array(3)).map((_item, i) => {
    const itemsCount = (() => {
      switch (i) {
        case 0:
          return 4;
        case 1:
          return 2;
        case 3:
          return 1;
        default:
          return 2;
      }
    })();
    return (
      <Box key={i} className={styles.transactionsRow}>
        <Skeleton
          key={i}
          variant="rectangular"
          className={`${styles.skeletonRect} ${styles.skeletonTitle}`}
        />
        <Box className={styles.transactionsBlock}>
          {Array.from(new Array(itemsCount)).map((_item, k) => (
            <Skeleton
              key={k}
              variant="rectangular"
              className={styles.skeletonRect}
            />
          ))}
        </Box>
      </Box>
    );
  });

const getTransactionDate = (date, monthNames) => {
  return `${monthNames[new Date(date).getMonth()]} ${new Date(
    date
  ).getUTCDate()}`;
};

const POPOVER_LIFETIME = 7000;

const Transactions = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const loader = <CircularProgress />;
  const timer = useRef(null);
  const pageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [buyOffset, setBuyOffset] = useState(0);
  const [sellOffset, setSellOffset] = useState(0);
  const [buyTransactions, setBuyTransactions] = useState([]);
  const [sellTransactions, setSellTransactions] = useState([]);
  const [buyTransactionsPending, setBuyTransactionsPending] = useState([]);
  const [sellTransactionsPending, setSellTransactionsPending] = useState([]);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [transactionToCancel, setTransactionToCancel] = useState(null);
  const [showTransactionsType, setShowTransactionsType] = useState(
    ORDER_TYPES.buy
  );
  const isLastBuyPage = useSelector(
    (state) => state.users.transactions.isLastBuyPage
  );
  const isLastSellPage = useSelector(
    (state) => state.users.transactions.isLastSellPage
  );
  const otherTransactionsDates = [];

  const monthNames = [
    t("default.january"),
    t("default.february"),
    t("default.march"),
    t("default.april"),
    t("default.may"),
    t("default.june"),
    t("default.july"),
    t("default.august"),
    t("default.september"),
    t("default.october"),
    t("default.november"),
    t("default.december"),
  ];

  const getData = async (type, offsetParam) => {
    type === ORDER_TYPES.buy
      ? setBuyOffset(offsetParam)
      : setSellOffset(offsetParam);

    const payload = {
      type: type,
      offset: 0,
      limit: offsetParam + TRANSACTIONS_LOAD_LIMIT,
    };

    await dispatch(fetchUserTransactions(payload))
      .unwrap()
      .then((d) => {
        if (type === ORDER_TYPES.buy) {
          const bts = d.executedTransactions.filter(
            (t) => t.type === ORDER_TYPES.buy
          );
          const pendingBts = d.pendingTransactions.filter(
            (t) => t.type === ORDER_TYPES.buy
          );
          setBuyTransactions(bts);
          setBuyTransactionsPending(pendingBts);
        }

        if (type === ORDER_TYPES.sell) {
          const sts = d.executedTransactions.filter(
            (t) => t.type === ORDER_TYPES.sell
          );
          const pendingSts = d.pendingTransactions.filter(
            (t) => t.type === ORDER_TYPES.sell
          );
          setSellTransactions(sts);
          setSellTransactionsPending(pendingSts);
        }

        if (type === ORDER_TYPES.drop) {
          const sts = d.executedTransactions.filter(
            (t) => t.type === ORDER_TYPES.drop
          );
          const pendingSts = d.pendingTransactions.filter(
            (t) => t.type === ORDER_TYPES.drop
          );
          setBuyTransactions((prevState) => {
            return [...prevState, ...sts];
          });
          setBuyTransactionsPending((prevState) => {
            return [...prevState, ...pendingSts];
          });
        }
      });
  };

  useEffect(() => {
    let unmounted = false;

    setLoading(true);

    !unmounted && setAnchorEl(pageRef.current);

    !unmounted &&
      getData(ORDER_TYPES.buy, 0).then(() => {
        getData(ORDER_TYPES.drop, 0);
      });
    !unmounted && getData(ORDER_TYPES.sell, 0).finally(() => setLoading(false));

    return () => {
      unmounted = true;
    };
  }, []);

  const getPricesSum = (transactions, transactionDate) => {
    const prices = transactions.map((item) => {
      if (item.status === TRANSACTIONS_STATUSES.cancelled) {
        return 0;
      }
      if (formatDate(item.date) === transactionDate) {
        return item.price;
      }
      return 0;
    });

    return roundToTwo(prices?.reduce((partialSum, a) => partialSum + a, 0));
  };

  const handleCancelTransaction = useCallback(
    (orderId) => {
      let cancelledTransaction;

      if (showTransactionsType === ORDER_TYPES.buy) {
        const items = buyTransactionsPending.filter(
          (item) => item.orderId !== orderId
        );
        setBuyTransactionsPending(items);

        cancelledTransaction = buyTransactionsPending.find(
          (item) => item.orderId === orderId
        );

        setTransactionToCancel(cancelledTransaction);
      }

      if (showTransactionsType === ORDER_TYPES.sell) {
        const items = sellTransactionsPending.filter(
          (item) => item.orderId !== orderId
        );
        setSellTransactionsPending(items);

        cancelledTransaction = sellTransactionsPending.find(
          (item) => item.orderId === orderId
        );
        setTransactionToCancel(cancelledTransaction);
      }

      if (showTransactionsType === ORDER_TYPES.drop) {
        const items = buyTransactionsPending.filter(
          (item) => item.orderId !== orderId
        );
        setBuyTransactionsPending(items);

        cancelledTransaction = buyTransactionsPending.find(
          (item) => item.orderId === orderId
        );

        setTransactionToCancel(cancelledTransaction);
      }

      setPopoverIsOpen(true);

      timer.current = setTimeout(() => {
        dispatch(cancelTransaction(cancelledTransaction?.orderId)).then(() => {
          setPopoverIsOpen(false);
        });
      }, POPOVER_LIFETIME);
    },
    [buyTransactionsPending, sellTransactionsPending, showTransactionsType]
  );

  const onUndoPress = () => {
    if (showTransactionsType === ORDER_TYPES.buy) {
      setBuyTransactionsPending([
        ...buyTransactionsPending,
        transactionToCancel,
      ]);
    }
    if (showTransactionsType === ORDER_TYPES.sell) {
      setSellTransactionsPending([
        ...sellTransactionsPending,
        transactionToCancel,
      ]);
    }
    if (showTransactionsType === ORDER_TYPES.drop) {
      setBuyTransactionsPending([
        ...buyTransactionsPending,
        transactionToCancel,
      ]);
    }
    clearTimeout(timer.current);
    setPopoverIsOpen(false);
  };

  const splitTransactionsByDate = (transactions) => {
    const d = new Date();
    const today = formatDate(d);
    const yesterday = formatDate(new Date(d.setDate(d.getUTCDate() - 1)));

    const todaysTransactions = transactions.filter((t) => {
      const tDate = formatDate(t.date);
      return tDate === today;
    });
    const yesterdaysTransactions = transactions.filter((t) => {
      const tDate = formatDate(t.date);
      return tDate === yesterday;
    });

    const otherTransactions = transactions.filter((t) => {
      const tDate = formatDate(t.date);
      return tDate !== yesterday && tDate !== today;
    });

    const otherTransactionsSorted = [...otherTransactions].sort((a, b) => {
      const aTime = new Date(a.date).getTime();
      const bTime = new Date(b.date).getTime();

      return bTime - aTime;
    });

    otherTransactionsSorted.forEach((t) => {
      if (!isDateInArray(t.date, otherTransactionsDates)) {
        otherTransactionsDates.push(t.date);
      }
    });

    const getTransactionsSum = (transactions, date) => {
      const pricesSum = getPricesSum(transactions, date);
      const currencySymbol = transactions[0]?.currencySymbol || "$";

      if (pricesSum > 0) {
        return (
          <>
            <BulletIcon className={styles.bulletIcon} />
            {currencySymbol}
            {getPricesSum(transactions, date)}
          </>
        );
      }

      return null;
    };

    return (
      <Box id="otherTransactionsContainer">
        {!!todaysTransactions?.length && (
          <Box className={styles.transactionsRow}>
            <Typography variant="h6" component="h4" className={styles.rowTitle}>
              {t("holdingdetails.today")}
              {getTransactionsSum(todaysTransactions, today)}
            </Typography>
            <Box className={styles.transactionsBlock}>
              {todaysTransactions.map((item) => (
                <TransactionItem key={item.orderId} transaction={item} />
              ))}
            </Box>
          </Box>
        )}
        {!!yesterdaysTransactions?.length && (
          <Box className={styles.transactionsRow}>
            <Typography variant="h6" component="h4" className={styles.rowTitle}>
              {t("trading.yesterday")}
              {getTransactionsSum(yesterdaysTransactions, yesterday)}
            </Typography>
            <Box className={styles.transactionsBlock}>
              {yesterdaysTransactions.map((item) => (
                <TransactionItem key={item.orderId} transaction={item} />
              ))}
            </Box>
          </Box>
        )}
        {!!otherTransactionsDates?.length && (
          <InfiniteScroll
            dataLength={otherTransactionsDates.length}
            next={() =>
              getData(
                showTransactionsType,
                (showTransactionsType === ORDER_TYPES.buy
                  ? buyOffset
                  : sellOffset) + TRANSACTIONS_LOAD_LIMIT
              )
            }
            hasMore={showTransactionsType ? !isLastBuyPage : !isLastSellPage}
            style={{ overflow: "hidden" }}
            loader={loader}
            endMessage=""
            scrollThreshold={SCROLL_THRESHOLD}
          >
            {otherTransactionsDates.map((transactionDate) => {
              return (
                <Box key={transactionDate} className={styles.transactionsRow}>
                  <Typography
                    variant="h6"
                    component="h4"
                    className={styles.rowTitle}
                  >
                    {getTransactionDate(transactionDate, monthNames)}{" "}
                    {getTransactionsSum(
                      otherTransactionsSorted,
                      transactionDate
                    )}
                  </Typography>
                  <Box className={styles.transactionsBlock}>
                    {otherTransactionsSorted.map((item) => {
                      if (
                        formatDate(item.date) === formatDate(transactionDate)
                      ) {
                        return (
                          <TransactionItem
                            key={item.orderId}
                            transaction={item}
                          />
                        );
                      }
                      return null;
                    })}
                  </Box>
                </Box>
              );
            })}
          </InfiniteScroll>
        )}
      </Box>
    );
  };

  return (
    <PageLayout>
      <Box ref={pageRef}>
        <Container className={styles.container}>
          <Typography variant="h2" component="h1" className={styles.title}>
            {t("home.transactions")}
          </Typography>
          <Box className={styles.header}>
            <Box className={styles.headerButtonsBlock}>
              <Button
                variant={
                  showTransactionsType === ORDER_TYPES.buy
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setShowTransactionsType(ORDER_TYPES.buy)}
                className={styles.headerBtn}
              >
                {t("trading.purchases")}
              </Button>
              <Button
                variant={
                  showTransactionsType === ORDER_TYPES.sell
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setShowTransactionsType(ORDER_TYPES.sell)}
                className={styles.headerBtn}
              >
                {t("trading.sales")}
              </Button>
            </Box>
          </Box>
          {loading ? <Skeletons /> : null}
          <Box className={styles.transactionsContainer}>
            {showTransactionsType === ORDER_TYPES.buy && (
              <>
                {!!buyTransactions.length || !!buyTransactionsPending.length ? (
                  <>
                    {!!buyTransactionsPending.length && (
                      <Box className={styles.transactionsRow}>
                        <Typography
                          variant="h6"
                          component="h4"
                          className={styles.rowTitle}
                        >
                          {t("orderdetails.pending")}
                        </Typography>
                        <Box className={styles.transactionsBlock}>
                          {buyTransactionsPending.map((item) => (
                            <TransactionItem
                              key={item.orderId}
                              transaction={item}
                              handleCancelTransaction={() =>
                                handleCancelTransaction(item.orderId)
                              }
                              showOptionsBtn
                            />
                          ))}
                        </Box>
                      </Box>
                    )}
                    {!!buyTransactions.length &&
                      splitTransactionsByDate(buyTransactions)}
                  </>
                ) : null}
                {!buyTransactions.length &&
                !buyTransactionsPending.length &&
                !loading ? (
                  <Placeholder text={t("home.noOrders")} />
                ) : null}
              </>
            )}
            {showTransactionsType === ORDER_TYPES.sell && (
              <>
                {!!sellTransactionsPending.length ||
                !!sellTransactions.length ? (
                  <>
                    {!!sellTransactionsPending.length && (
                      <Box className={styles.transactionsRow}>
                        <Typography
                          variant="h6"
                          component="h4"
                          className={styles.rowTitle}
                        >
                          {t("orderdetails.pending")}
                        </Typography>
                        <Box className={styles.transactionsBlock}>
                          {sellTransactionsPending.map((item) => (
                            <TransactionItem
                              key={item.orderId}
                              transaction={item}
                              handleCancelTransaction={() =>
                                handleCancelTransaction(item.orderId)
                              }
                              showOptionsBtn
                            />
                          ))}
                        </Box>
                      </Box>
                    )}
                    {!!sellTransactions.length &&
                      splitTransactionsByDate(sellTransactions)}
                  </>
                ) : null}
                {!sellTransactions.length &&
                !sellTransactionsPending.length &&
                !loading ? (
                  <Placeholder text={t("home.noOrders")} />
                ) : null}
              </>
            )}
          </Box>
        </Container>
        <Toaster
          name="cancel-popover"
          isOpen={popoverIsOpen}
          anchorEl={anchorEl}
          onUndoPress={onUndoPress}
          handleClose={() => setPopoverIsOpen(false)}
          message={t("trading.transactionCancelled")}
          anchorOrigin={{ horizontal: 70 }}
        />
      </Box>
    </PageLayout>
  );
};

export default Transactions;
