import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg";
import ActiveLink from "components/ActiveLink";
import { ANALYTICS_EVENTS, EMULATE, PATHS } from "constants/index";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { getProfileId } from "redux/selectors/usersSelectors";
import { logout } from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { isEmulationMode } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import UserPool from "services/userPool";
import { useIsMobile } from "utils/hooks";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Settings.module.scss";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const isMobile = useIsMobile();
  const isMobileSmall = useMediaQuery("(max-width:1140px)");

  const profileId = useSelector(getProfileId);

  const handleLogout = () => {
    Analytics.track(ANALYTICS_EVENTS.LOGOUT, { user: profileId });
    Analytics.reset();
    const cognitoUser = UserPool()?.getCurrentUser();
    if (cognitoUser) {
      if (isEmulationMode()) {
        console.error(EMULATE.ACCESS_ERROR);
      } else {
        cognitoUser.signOut();
      }
    }
    dispatch(logout());
    navigate(PATHS.LOGIN, { replace: true });
  };

  const Aside = () => (
    <Box className={styles.aside}>
      <ActiveLink
        to={PATHS.SETTINGS_ACCOUNTS}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.account")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      {isMobileSmall ? (
        <ActiveLink
          to={PATHS.REFERRALS}
          className={styles.menuItem}
          activeClass={styles.menuItemActive}
        >
          <Typography variant="h6">{t("home.referrals")}</Typography>
          <IconRight className={styles.icon} />
        </ActiveLink>
      ) : null}
      <ActiveLink
        to={PATHS.SETTINGS_SECURITY}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.security")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      {/* temporary commented */}
      {/* <ActiveLink
        to="/settings/subscription"
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.subscription")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink> */}
      <ActiveLink
        to={PATHS.SETTINGS_EMAIL_NOTIFICATIONS}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.emailNotifications")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      <ActiveLink
        to={PATHS.SETTINGS_TRANSFERS}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.transfers")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      <ActiveLink
        to={PATHS.SETTINGS_HELP}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
        matchEnd={false}
      >
        <Typography variant="h6">{t("settings.help")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      <ActiveLink
        to={PATHS.SETTINGS_POLICIES}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
        matchEnd={false}
      >
        <Typography variant="h6">{t("settings.policies")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      {isMobileSmall ? (
        <ActiveLink
          className={`${styles.menuItem} ${styles.menuItemLogout}`}
          matchEnd={false}
          onClick={handleLogout}
        >
          <Typography variant="h6">{t("default.logOut")}</Typography>
          <IconRight className={styles.icon} />
        </ActiveLink>
      ) : null}
    </Box>
  );

  const isNavVisible = location.pathname === PATHS.SETTINGS;

  return (
    <PageLayout
      title={!isMobileSmall || isNavVisible ? t("default.settings") : null}
      isBack={isMobileSmall && isNavVisible}
      isShowHeader={!isMobileSmall || isNavVisible}
      backBtnProps={{ link: PATHS.PROFILE }}
    >
      <Box className={styles.container}>
        {isMobile && !isNavVisible ? null : <Aside />}
        {!isMobile || !isNavVisible ? (
          <Box className={styles.settingsContent}>
            <Outlet />
          </Box>
        ) : null}
      </Box>
    </PageLayout>
  );
};

export default Settings;
