import { Container } from "@mui/material";
import { SearchResultList } from "components/Layout/Header/components/SearchResultList/SearchResultList";
import { SearchBar } from "components/SearchBar/SearchBar";
import React, { useState } from "react";
import {
  getFilteredInfluencers,
  initialLoaders,
} from "utils/getFilteredInfluencers";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Search.module.scss";

export const Search = () => {
  const [loaders, setLoaders] = useState(initialLoaders);
  const [data, setData] = useState(initialLoaders);
  const [isResultOpened, setResultOpen] = useState(false);

  const handleSearch = (searchTerm) => {
    getFilteredInfluencers({ params: { searchTerm }, setLoaders, setData });
    setResultOpen(true);
  };

  return (
    <PageLayout>
      <Container className={styles.container}>
        <SearchBar
          onSeacrhChanged={handleSearch}
          classNameWrapper={styles.search}
          isResultOpened={isResultOpened}
          setResultOpen={setResultOpen}
          classNameResult={styles.searchResult}
          isClearDataByOutClick={false}
        >
          <SearchResultList data={data} loaders={loaders} />
        </SearchBar>
      </Container>
    </PageLayout>
  );
};
